import React from 'react'
import Shell from '../components/shell'
import Helmet from 'react-helmet'
import Container from '../components/global/container'
import Info from '../components/index/info'
import EdProjects from '../components/index/edprojects'


const IndexPage = () => {
  return (
    <Shell>
      <Helmet title="Christian Burkhart" />

      <Container>
        <Info />
        <EdProjects />
      </Container>
      
    </Shell>
  )
  }

export default IndexPage
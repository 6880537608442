import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


const Info = (props) => {
  return (
    <div className="mt-10">

      <div className="flex flex-row items-center mb-5">
      <h2 className="text-3xl font-bold mr-3">Hi, I am Christian.</h2>
      <StaticImage src="../../images/image_cropped.png" alt="Christian Burkhart" width={60} height={60} className="rounded-full" placeholder="none" />
      </div>
      


      <p className="text-lg">I'm an instructional designer, frontend developer and data scientist. Currently, I am working as a Senior Instructional Designer at <a href="https://www.appliedai.de/">appliedAI</a>. I'm from <a href="https://en.wikipedia.org/wiki/Illertissen">Illertissen, Germany</a> but I currently live in Munich. Also, I enjoy <a href="https://www.goodreads.com/review/list/18781909-christian-burkhart?shelf=read">reading</a> and running. Before I was working as an Instructional Designer I worked as an <a href="https://scholar.google.com/citations?user=mBSUlHUAAAAJ&hl=de&oi=ao">Educational Scientist</a> in Freiburg im Breisgau, Germany.</p>

      <div className="flex flex-row items-center my-6">
        <a href="https://de.linkedin.com/in/christian-burkhart" className="mr-2"><StaticImage src="../../images/icons/linkedin.png" alt="LinkedIn" width={34} placeholder="none" /> </a>
        <a href="https://github.com/ch-bu" className="mr-2"><StaticImage src="../../images/icons/github.png" alt="Twitter" width={34} placeholder="none" /> </a>
        <a href="https://twitter.com/ChBurkhart"><StaticImage src="../../images/icons/twitter.png" alt="Twitter" width={34} placeholder="none" /> </a>
      </div>

    </div>
  )
}

export default Info

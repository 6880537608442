import React from 'react'
import styled from 'styled-components'


const EdProjects = (props) => {
  return (
    <div className="my-5">

      <p className="text-lg">I especially enjoy creating instructional materials in data science. Here are some of my projects:</p>

      <ul className='mt-4'>
        <li className='text-lg'><a href="https://www.udemy.com/course/ggplot2-fundamentals/?couponCode=918369C59B4026B3A23B">ggplot2 fundamentals: A training course on ggplot2</a>. The course is intended for anyone who wants to learn the data visualization package ggplot2 from the ground up. </li>
        <li className='text-lg'><a href="https://ggplot2tor.com/">ggplot2tor</a>: An educational website  about the ggplot2 package for data visualization in R.</li>
        <li className="text-lg"><a href="https://rise.articulate.com/share/d_yeCBxX_Lh0OdWyl-svvDPBkm3Tsde1#/">An online course on inferential statistics</a>: This course covers a regression approach to inferential statistics and teaches students how to answer research questions in the social sciences. The tools for this course are R and Jamovi. </li>
        <li className="text-lg"><a href="https://elearningdatenundki.gtsb.io/">E-Book on Online Learning (written in German)</a>: This e-book offers a comprehensive overview of current research on online learning for instructional designers. It covers seven essential topics that every instructional designer should know to create effective online courses.</li>
        <li className='text-lg'><a href="https://saoirse.surge.sh/">Saoirse</a>: A free and open source e-learning framework based on Markdown.</li>
        <li className='text-lg'>Also, I have created websites for some clients: <a href="https://www.david-seitz.de/">David Seitz</a>, <a href="https://www.petite-hirondelle.de/">Petite Hirondelle</a></li>
      </ul>

     
    </div>
  )
}

export default EdProjects

import React from 'react'
import Helmet from 'react-helmet'
import {ThemeProvider, createGlobalStyle} from 'styled-components'
import styled from 'styled-components'

// const GlobalStyle = createGlobalStyle`
//   *::before,
//   *::after {
//     box-sizing: border-box;
//   }

//   /* ::selection {
//     color: white;
//     background-color: #621B18;
//   } */

//   html {
//     font-size: 16px;
//     box-sizing: border-box;
//     font-family: -apple-system, BlinkMacSystemFont, Roboto;
//     border: 0;
//     margin: 0;
//   }

//   p {
//     color: #22292F;
//   }

//   a {
//     text-decoration: underline;
//   }

//   h1 {
//     font-size: 1.7rem !important;
//   }

//   h2 {
//     font-size: 1.5rem;
//     font-weight: bold;
//     /* z-index: 5; */

//     /* &:before {
//       content: '';
//       width: 0.95em;
//       height: 0.95em;
//       background: #FFF9C2;
//       position: absolute;
//       transform: translateX(-1.8rem) translateY(-0.5rem);
//       border: 1px solid #453411;
//     } */
//   }

//   h3 {
//     font-weight: bold;
//     font-size: 1.3rem;
//   }

//   li {
//     font-size: 0.9rem;
//   }
// `



const Shell = (props) => {
  return (
    <div>

      <Helmet
        title="Christian Burkhart"
        meta={[
          { name: 'description', content: 'Educational Scientist, Instructional Designer, Data Visualization Engineer' },
          { name: 'keywords', content: 'educational sciences, front-end development, data analysis' },
          { name: 'author', content: 'Christian Burkhart'}
        ]}
      >
        <html lang="en" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Rubik:300,400,700,900&display=swap" rel="stylesheet" />
      </Helmet>

      {/* <Header siteTitle="Christian Burkhart"/> */}
      {props.children}
      {/* <Footer /> */}

     
    </div>
  )
}

export default Shell
